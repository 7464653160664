function googleChart() {
    google.charts.load('current', { 'packages': ['gauge'] });
    google.charts.setOnLoadCallback(drawChart);

    function drawChart() {

        var data = google.visualization.arrayToDataTable([
            ['Label', 'Value'],
            ['', 80]
        ]);

        var options = {
            width: 400,
            height: 400,
            redFrom: 0,
            redTo: 45,
            //orangeFrom: 45, orangeTo: 60,
            yellowFrom: 45,
            yellowTo: 75,
            greenFrom: 75,
            greenTo: 100,
            //blueFrom: 85, blueTo: 100,
            minorTicks: 5
        };

        var chart = new google.visualization.Gauge(document.getElementById('chart_div'));

        data.setValue(0, 1, 75);

        chart.draw(data, options);
        document.getElementsByTagName('circle')[0].remove();
        document.getElementsByTagName('circle')[0].remove();


        // para coger la y parseInt(document.getElementsByTagName('path')[document.getElementsByTagName('path').length - 1].getAttribute('d').split(',')[document.getElementsByTagName('path')[document.getElementsByTagName('path').length - 1].getAttribute('d').split(',').length -1 ])

        // para coger la x parseInt(document.getElementsByTagName('path')[document.getElementsByTagName('path').length - 1].getAttribute('d').split(',')[document.getElementsByTagName('path')[document.getElementsByTagName('path').length - 1].getAttribute('d').split(',').length -2 ])

        // para poner la posicion del punto coordenada y: 
        document.getElementsByTagName('circle')[0].setAttribute("cy", parseInt(document.getElementsByTagName('path')[document.getElementsByTagName('path').length - 1].getAttribute('d').split(',')[document.getElementsByTagName('path')[document.getElementsByTagName('path').length - 1].getAttribute('d').split(',').length - 1]));

        // para poner la posicion del punto coordenada x: 
        document.getElementsByTagName('circle')[0].setAttribute("cx", parseInt(document.getElementsByTagName('path')[document.getElementsByTagName('path').length - 1].getAttribute('d').split(',')[document.getElementsByTagName('path')[document.getElementsByTagName('path').length - 1].getAttribute('d').split(',').length - 2]));

        // reducir el radio del point

        document.getElementsByTagName('circle')[0].setAttribute("r", "12");

        // eliminar la aguja 

        document.getElementsByTagName('path')[document.getElementsByTagName('path').length - 1].remove();

        document.getElementsByTagName('circle')[0].setAttribute('fill', 'white');
        document.getElementsByTagName('circle')[0].setAttribute('fill-opacity', '0.4');
        document.getElementsByTagName('circle')[0].setAttribute('stroke', '#4684ee');
        document.getElementsByTagName('circle')[0].setAttribute('stroke-width', '10');

        document.getElementsByTagName('text')[2].setAttribute('y', '250');
        document.getElementsByTagName('text')[2].setAttribute('font-size', '80');

    }

}