var personalid_front_face;
var personalid_back_face;
var credit_card_selfie;
var document_confirming_address;

function isUploadSupported() {
    if (navigator.userAgent.match(/(Android (1.0|1.1|1.5|1.6|2.0|2.1))|(Windows Phone (OS 7|8.0))|(XBLWP)|(ZuneWP)|(w(eb)?OSBrowser)|(webOS)|(Kindle\/(1.0|2.0|2.5|3.0))/)) {
        return false;
    }
    var elem = document.createElement('input');
    elem.type = 'file';
    return !elem.disabled;
};

function fileChange(e, id) {
    var file = e.target.files[0];
    if (file) {
        if (/^image\//i.test(file.type)) {
            readFile(file, id);
        } else {
            alert('Not a valid image!');
        }
    }
}

function getPersonalid_front_face() {
    return personalid_front_face;
}

function getPersonalid_back_face() {
    return personalid_back_face;
}

function getCredit_card_selfie() {
    return credit_card_selfie;
}

function getDocument_confirming_address() {
    return document_confirming_address;
}

function initUploadFile() {
    if (window.File && window.FileReader && window.FormData) {

        var $inputFielddni1 = $('#dni1');
        var $inputFielddni2 = $('#dni2');
        var $inputFieldselfie = $('#selfie');
        var $inputFieldfileDoc = $('#fileDoc');

        $inputFielddni1.on('change', function(e) {
            fileChange(e, "dni1");
        });
        $inputFielddni2.on('change', function(e) {
            fileChange(e, "dni2");
        });
        $inputFieldselfie.on('change', function(e) {
            fileChange(e, "selfie");
        });
        $inputFieldfileDoc.on('change', function(e) {
            fileChange(e, "fileDoc");
        });
    } else {
        alert("File upload is not supported!");
    }

}


function readFile(file, id) {
    var reader = new FileReader();

    reader.onloadend = function() {
        processFile(reader.result, file.type, id);
    }

    reader.onerror = function() {
        alert('There was an error reading the file!');
    }

    reader.readAsDataURL(file);
}

function processFile(dataURL, fileType, id) {
    var maxWidth = 60;
    var maxHeight = 60;

    var image = new Image();
    image.src = dataURL;

    image.onload = function() {
        var width = image.width;
        var height = image.height;
        var newWidth;
        var newHeight;
        var shouldResize = (width > maxWidth) || (height > maxHeight);

        if (!shouldResize) {
            newHeight = height;
            newWidth = width;
        } else {

            if (width > height) {
                newHeight = height * (maxWidth / width);
                newWidth = maxWidth;
            } else {
                newWidth = width * (maxHeight / height);
                newHeight = maxHeight;
            }
        }




        var canvas = document.createElement('canvas');
        document.getElementById("div" + id).appendChild(canvas);

        canvas.width = newWidth;
        canvas.height = newHeight;
        canvas.id = "canvas" + id;

        var context = canvas.getContext('2d');

        context.drawImage(this, 0, 0, newWidth, newHeight);

        // dataURL = canvas.toDataURL(fileType);

        canvas.toBlob(function(blob) {
            var newImg = document.createElement("img"),
                url = URL.createObjectURL(blob);

            newImg.onload = function() {
                // no longer need to read the blob so it's revoked
                URL.revokeObjectURL(url);
            };

            newImg.src = url;
            switch (id) {
                case 'dni1':
                    personalid_front_face = newImg;
                    break;
                case 'dni2':
                    personalid_back_face = newImg;
                    break;
                case 'selfie':
                    credit_card_selfie = newImg;
                    break;
                case 'fileDoc':
                    document_confirming_address = newImg;
                    break;
                default:

            }
            // document.body.appendChild(newImg);
        });

        //  document.getElementById("div" + id).childNodes[3].style.left = "2px";
        //  document.getElementById("div" + id).childNodes[3].style.position = "relative";
        //  document.getElementById("div" + id).childNodes[3].style.zIndex = "2";

        document.getElementById("div" + id).childNodes[1].style.display = "none"


        document.getElementById("input" + id).value = "done";

        //sendFile(dataURL);
    };

    image.onerror = function() {
        alert('There was an error processing your file!');
    };
}

function sendFile(fileData) {
    var formData = new FormData();

    formData.append('imageData', fileData);

    $.ajax({
        type: 'POST',
        url: '/your/upload/url',
        data: formData,
        contentType: false,
        processData: false,
        success: function(data) {
            if (data.success) {
                alert('Your file was successfully uploaded!');
            } else {
                alert('There was an error uploading your file!');
            }
        },
        error: function(data) {
            alert('There was an error uploading your file!');
        }
    });
}